"use client";

import { ArrowLeftIcon, ArrowRightIcon, XMarkIcon } from "@heroicons/react/16/solid";
import React, { useEffect } from "react";

interface ModalProps {
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
}

export const Modal: React.FC<ModalProps> = ({ visible, children, onClose, onPrevious, onNext }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "Escape":
          onClose();
          break;
        case "ArrowLeft":
          onPrevious?.();
          break;
        case "ArrowRight":
          onNext?.();
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    document.body.style.overflow = visible ? "hidden" : "";

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = visible ? "hidden" : "";
    };
  }, [visible, onClose, onPrevious, onNext]);

  return (
    <div
      className="fixed top-0 left-0 inset-0 bg-black/70 flex items-center justify-center z-50"
      style={{ display: visible ? "" : "none" }}
      onClick={onClose}
    >
      {onPrevious && (
        <ArrowLeftIcon
          className="fixed rounded-4xl size-8 p-1 left-4 z-70 top-1/2 text-white bg-gray-950 opacity-70"
          onClick={(e) => {
            e.stopPropagation();
            onPrevious();
          }}
        />
      )}
      {onNext && (
        <ArrowRightIcon
          className="fixed rounded-4xl size-8 p-1  right-4 z-70 top-1/2  text-white bg-gray-950 opacity-70"
          onClick={(e) => {
            e.stopPropagation();
            onNext();
          }}
        />
      )}
      <XMarkIcon className="fixed rounded-4xl size-8 p-1 right-4 top-4 z-70 text-white bg-gray-950 opacity-70" />
      {visible && (
        <div
          className="max-w-screen max-h-svh overflow-hidden shadow-black/70 shadow-2xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white max-w-screen max-h-screen overflow-auto">{children}</div>
        </div>
      )}
    </div>
  );
};
